import {it} from "./locales/it";
import {en} from "./locales/en";
import {es} from "./locales/es";
import {pt} from "./locales/pt";
switch(document.getElementsByTagName("html")[0].getAttribute('lang')) {
  case "it":
    var infoText1 = it.cookie.info1;
    var infoText2 = it.cookie.info2;
    var acceptButtonText = it.cookie.accept;
    var infoLinkText = it.cookie.read;
    break;
  case "en":
    var infoText1 = en.cookie.info1;
    var infoText2 = en.cookie.info2;
    var acceptButtonText = en.cookie.accept;
    var infoLinkText = en.cookie.read;
    break;
  case "es":
    var infoText1 = es.cookie.info1;
    var infoText2 = es.cookie.info2;
    var acceptButtonText = es.cookie.accept;
    var infoLinkText = es.cookie.read;
    break;
  case "pt":
    var infoText1 = pt.cookie.info1;
    var infoText2 = pt.cookie.info2;
    var acceptButtonText = pt.cookie.accept;
    var infoLinkText = pt.cookie.read;
    break;
  default:
}

// Nome del cookie impostato. Puoi cambiarlo a tuo piecere.
var acceptedCookieName = 'cookie_policy_accepted';

// Durata del cookie in giorni
var acceptedCookieLife = 365;

// Deve essere univoco all'interno della pagina
var infoBannerId = "cookie_info_breve";

// Deve essere univoco all'interno della pagina
var acceptButtonId = "cookie_accept_button";

var infoBanner =
  "<div id='" + infoBannerId + "' class='outer'>" +
  "<div class='inner'>" +
  "<div class='text'>" +
  infoText1 + " <i><b>" + acceptButtonText + "</b></i>, " + infoText2 +
  "</div>" +
  "<div class='buttons'>" +
  "<a href='#' id='" + acceptButtonId + "' class='accept'>" + acceptButtonText + "</a>" +
  "<a href='/CookiesPolicyBAGG.pdf' class='cookies-link more_info' target='_blank'>" + infoLinkText + "</a>" +
  "</div>" +
  "</div>" +
  "</div>";


document.addEventListener("turbolinks:load", function() {
  // se è presente il cookie "acceptedCookieName" con valore 'true', allora
  if (getCookie(acceptedCookieName) === 'true') { // i cookie sono stati accettati
    optedIn();         // sblocca tutti gli elementi
  } else {            // cookie non accettati
    optInHandler();   // mostra banner con informativa breve
  }
});

// Gestione del visitatore che deve ancora dare il consenso
function optInHandler() {
  $('body').append(infoBanner); // Inserisci il banner informativo
  setTimeout(readUserInput, 2000) // aspetta due secondi per dar tempo all'utente di notare il banner
}
function readUserInput() {
  // Accettazione mediante scroll
  var accepted = false; // questa variabile serve a rilevare l'accettamento solo una volta
  window.onscroll = function (e) {
    if (accepted == false) {
      accepted = true;
      cookieOptIn();
    }
  }
  // Accettazione con click su acceptButton
  $('#' + acceptButtonId).click(function () {
    accepted = true;
    cookieOptIn();
  });
}
// Salvataggio del consenso con cookie tecnico 'acceptedCookieName'
function cookieOptIn() {
  setCookie(acceptedCookieName, 'true', acceptedCookieLife); //salvataggio del cookie sul browser dell'utente
  $('#' + infoBannerId).hide();
  console.log("opted In");
  optedIn();
}

// Sblocca gli script esterni ricaricandoli in fondo alla pagina HTML
function reloadJs(src) {
  src = $('script[data-blocked="' + src + '"]').attr("data-blocked");
  $('script[data-blocked="' + src + '"]').remove();
  $('<script/>').attr('src', src).appendTo('body');
}

// Sblocca tutti gli elementi bloccati per l'utente che ha accettato esplicitamente i cookie (ha cioè fatto "opt-in")
function optedIn() {
  // sblocca gli script esterni bloccati con 'data-blocked'
  $("head script[data-blocked]").each(function () {
    reloadJs($(this).attr('data-blocked'));
  });

  // sblocca gli script nell'head bloccati con 'type=text/blocked'
  $("head script[type='text/blocked']").each(function () {
    $(this).attr('type', 'text/javascript'); //cambia il type dello script per renderlo eseguibile
    $.globalEval($(this).html()); //esegui lo script
  });

  // sblocca iframes, immagini e altri elementi bloccati con 'data-blocked'
  $("body [data-blocked]").each(function () {
    $(this).attr('src', $(this).attr('data-blocked')).removeAttr('data-blocked') //ripristina l'attributo src
  });
  // sblocca gli script in embed bloccati con 'type=text/blocked'
  $("body script[type='text/blocked']").each(function () {
    $(this).attr('type', 'text/javascript'); //cambia il type dello script per renderlo eseguibile
    $.globalEval($(this).html()); //esegui lo script
  });
}

// Legge tutti i cookie e li inserisce nell'oggetto chiave/valore 'cookies'
function getCookies() {
  var cookies = {};
  var all = document.cookie; // Get all cookies in one big string
  if (all === "")
    return cookies;
  var list = all.split("; ")
  for (var i = 0; i < list.length; i++) {
    var cookie = list[i]
    var p = cookie.indexOf("=");
    var name = cookie.substring(0, p);
    var value = cookie.substring(p + 1);
    value = decodeURIComponent(value);
    cookies[name] = value;
  }
  return cookies;
}
// restituisce il valore di un cookie selezionato per nome
function getCookie(name) {
  var cookies = getCookies();
  return cookies[name];
}

// imposta un cookie con 'name', 'value' e giorni di durata
function setCookie(name, value, days) {
  var now = new Date();
  var expiration = new Date(now.getTime() + parseInt(days) * 24 * 60 * 60 * 1000);
  var cString = name + '=' + escape(value) + '; expires=' + expiration.toGMTString() + '; path=/; domain=baggworld.com;';
  document.cookie = cString;
  return cString;
}

// elimina un cookie selezionato per nome
function delCookie(name) {
  setCookie(name, '', -1);
}