import Uppy from "@uppy/core";
import FileInput from '@uppy/file-input';
import StatusBar from '@uppy/status-bar';
import Tus from '@uppy/tus'
import {it} from "../locales/it";
import {en} from "../locales/en";
import {es} from "../locales/es";
import {pt} from "../locales/pt";


// Uppy file uploader
export function initializeResourceUppy() {
  const $container = $('#resource_uppy');
  if ( $container.length === 0 ) { return; }

  // evita di inizializzare più volte l'elemento
  if ( $container.data('uppyInit') ) { return; }
  $container.data('uppyInit', true);

  let locale;
  let lang = $('html').attr('lang');
  switch(lang) {
    case 'it':
      locale = it;
      break;
    case 'pt':
      locale = pt;
      break;
    case 'es':
      locale = es;
      break;
    default:
      locale = en
  }

  let uppy = Uppy({
    debug: false,
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1
    },
    onBeforeFileAdded: (currentFile, files) => {
      let valid_mime_types = [
        'application/pdf', 'application/zip', // Document
        'audio/mp3', 'audio/wav', 'audio/x-ms-wma', 'audio/aac', 'audio/ogg', 'text/vnd.qt.linguist', 'audio/x-m4a',
        'audio/ac3', 'audio/x-aiff', 'audio/flac', // Audio
        'video/mp4', 'video/quicktime', 'video/x-ms-wmv', 'video/x-msvideo', 'video/webm', 'video/x-flv' // Video
      ];

      // console.log(currentFile);

      if(!valid_mime_types.includes(currentFile.type)) {
        iziToast.show({
          color: "red",
          title: locale.uppy_error_file_format.title,
          message: locale.uppy_error_file_format.message,
          position: "center"
        });
        return false
      }
    }
  }).use(Tus, {
    endpoint: '/files',
    chunkSize: 5*1024*1024
  }).use(FileInput, {
    target: $container.find('.uppy-file-choose').get(0),
    pretty: true,
    inputName: 'files[]',
    locale: {
      strings: {
        chooseFiles: locale.strings.chooseFiles,
      }
    }
  }).use(StatusBar, {
    id: 'StatusBar',
    target: $container.find('.uppy-upload-progress').get(0),
    replaceTargetContent: true,
    hideAfterFinish: true,
    showProgressDetails: true,
    hideUploadButton: true,
    hideRetryButton: false,
    hidePauseResumeButton: false,
    hideCancelButton: false,
    locale: {
      strings: {
        uploading: locale.strings.uploading,
        complete: locale.strings.complete
      }
    }
  })

  uppy.on('file-added', (file) => {
    $("#ajax-modal-lg").modal("show");
    // nuovo file
    $("#resource_name").val(file.name);
    $("#resource_name").parent().addClass('is-filled');
    // nuova versione
    // $("#document_name").val(file.name);
    // $("#document_name").parent().addClass('is-filled');
    $container.find('.uppy-FileInput-btn').attr("disabled", "disabled");
  });

  uppy.on('file-removed', (file) => {
    $container.find('.uppy-FileInput-btn').attr("disabled", null);
  });

  uppy.on('complete', (result) => {
    if(result.successful.length) {
      // console.log(result.successful[0]);
      $container.find('.uppy-target').val(JSON.stringify(result.successful[0]));
      $container.find('.uppy-tmp-file b').text(result.successful[0].name);
      $container.closest('form').find("input[type='submit']").removeAttr('disabled');
      $container.find('.uppy-FileInput-btn').hide();
    } else {
      // TODO!
      $container.find('.uppy-FileInput-btn').attr("disabled", null);
      $container.find('.uppy-FileInput-btn').show();
    }
  });

}