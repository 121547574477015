import {it} from "../locales/it";
import {en} from "../locales/en";
import {es} from "../locales/es";
import {pt} from "../locales/pt";
let locale;
let lang = $('html').attr('lang');
switch(lang) {
  case 'it':
    locale = it;
    break;
  case 'pt':
    locale = pt;
    break;
  case 'es':
    locale = es;
    break;
  default:
    locale = en
}
export const items = {
  folder:{
    create_new: {
      name: locale.create_new_dir,
      icon: "fas fa-plus-square",
      callback: function(key, opt){
        $.get('/folders/new.js', {parent_id: opt.$trigger.data('id')})
      }
    },
    edit: {
      name: locale.edit,
      icon: "fas fa-edit",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('id')}/edit.js`)
      }
    },
    publish: {
      name: locale.publish,
      icon: "fas fa-eye",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('id')}/publish_preview.js`)
      }
    },
    unpublish: {
      name: locale.unpublish,
      icon: "fas fa-eye-slash",
      callback: function(key, opt){
        dataConfirmModal.confirm({
          title: locale.confirm_unpublish_dir.title,
          text: locale.confirm_unpublish_dir.text(opt.$trigger.data('name')),
          onConfirm: function(){
            $.ajax({
              url: `/folders/${opt.$trigger.data('id')}/unpublish.js`,
              method: "PATCH"
            })
          }
        })
      }
    },
    upload: {
      name: locale.upload,
      icon: "fas fa-upload",
      className: "uppy-upload",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('id')}/resources/new.js`)
      }
    },
    delete: {
      name: locale.delete,
      icon: "fas fa-trash",
      callback: function(key, opt){
        dataConfirmModal.confirm({
          title: locale.confirm_remove_dir.title(opt.$trigger.data('name')),
          text: locale.confirm_remove_dir.text,
          commit: locale.confirm_remove_dir.commit,
          cancel: locale.confirm_remove_dir.cancel,
          onConfirm: function(){
            $.ajax({
              url: `/folders/${opt.$trigger.data('id')}.js`,
              method: "DELETE"
            })
          }
        })
      }
    }
  },
  resource:{
    edit: {
      name: locale.edit,
      icon: "fas fa-edit",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}/edit.js`)
      }
    },
    publish: {
      name: locale.publish,
      icon: "fas fa-eye",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}/publish_preview.js`)
      }
    },
    unpublish: {
      name: locale.unpublish,
      icon: "fas fa-eye-slash",
      callback: function(key, opt){
        dataConfirmModal.confirm({
          title: locale.confirm_unpublish_file.title,
          text: locale.confirm_unpublish_file.text(opt.$trigger.data('name')),
          onConfirm: function(){
            $.ajax({
              url: `/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}/unpublish.js`,
              method: "PATCH"
            })
          }
        })
      }
    },
    version: {
      name: locale.new_version,
      icon: "fas fa-upload",
      className: "uppy-upload",
      callback: function(key, opt){
        $.get(`/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}/new_version.js`)
      }
    },
    download: {
      name: locale.download,
      icon: 'fas fa-download',
      callback: function(key, opt) {
        window.location.assign(`/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}/download`)
      }
    },
    delete: {
      name: locale.delete,
      icon: "fas fa-trash",
      callback: function(key, opt){
        dataConfirmModal.confirm({
          title: locale.confirm_remove_file.title(opt.$trigger.data('name')),
          text: locale.confirm_remove_file.text,
          commit: locale.confirm_remove_dir.commit,
          cancel: locale.confirm_remove_dir.cancel,
          onConfirm: function(){
            $.ajax({
              url: `/folders/${opt.$trigger.data('folder-id')}/resources/${opt.$trigger.data('id')}`,
              method: "DELETE"
            })
          }
        })
      }
    }
  }
}
