import feather from "feather-icons";

export function initializeApp() {
  feather.replace();
  $('[data-rel="tooltip"]').tooltip({});
  $('[data-toggle="popover"]').popover({});

  $(".select-with-autocomplete").select2({
    theme: 'bootstrap',
    language: 'it'
  });

  $('.autocomplete-users').each(function() {
    const $input = $(this);
    if (!$input.data('typeaheadInit')) {
      $input.data('typeaheadInit', true);
      $input.typeahead({
        order: null,
        maxItem: 15,
        dynamic: true,
        delay: 300,
        cancelButton: false,
        hint: true,
        template: function(query, item) {
          return "<span>{{label}}</span>"
        },
        source: {
          display: "label",
          url: [{
            url: $('.autocomplete-users').data('autocomplete-url'),
            data: {
              query: "{{query}}"
            }
          }, 'users']
        },
        callback: {
          onClickAfter: function(node, a, item, event) {
            node.closest('form').find($('.autocomplete-users').data('autocomplete-target')).val(item.value);
          },
          onSearch: (node, query) => {
            node.closest('form').find($('.autocomplete-users').data('autocomplete-target')).val('');
          }
        }
      });
    }
  });

  $('.js-remove-referent').off('click').on('click', function() {
    $('.js-referent-id').val("");
    $('.js-referent-id').trigger('change');
    $('.autocomplete-users').closest('.form-group').removeClass('d-none');
    $('.referent-email-wrapper').addClass('d-none');
  });

  // data-confirm-modal: confirm dialogs as bootstrap modal
  dataConfirmModal.setDefaults({commitClass: "text-primary", cancelClass: "text-muted"});

  $('input.bootstrap-file-input:not(.no-bootstrap)').fileinput({
    language: "it",
    msgPlaceholder: 'File...',
    msgUploadEmpty: 'File...',
    msgNoFilesSelected: 'Nessun file selezionato'
  });

  $('.tags-input').each(function() {
    let elt = $(this);

    elt.tagsInput({
      'autocomplete': { source: elt.data('source') },
      'onAddTag': function(input, value) {
        $.post(elt.data('add-path'), {label: value});
      },
      'onRemoveTag': function(input, value) {
        $.post(elt.data('remove-path'), {label: value });
      }
    });
    return elt
  });

  $("#sidebar").resizable({
    minWidth: 250, maxWidth: 500,
    stop: function( event, ui ) {
      var originalSize = ui.originalSize;
      var currentSize = ui.size;
      var diffSize = currentSize.width - originalSize.width;
      $('.main').css('width', '-='+diffSize+'px');
    }
  });
}