const uppy_italian = require('@uppy/locales/lib/it_IT');
const italian = {
  info: "Dettagli",
  create_new_dir: "Nuova Cartella",
  edit: "Modifica",
  publish: "Pubblica",
  unpublish: "Annulla pubblicazione",
  delete: "Elimina",
  upload: "Carica File",
  download: "Scarica File",
  new_version: "Nuova Versione",
  confirm_remove_dir: {
    title: function(dir){
      return `Conferma eliminazione di "${dir}"`
    },
    text: "L'eliminazione della cartella implica l'eliminazione di tutti i file e cartelle contenuti in essa. Continuare con l'eliminazione?",
    commit: "Continua",
    cancel: "Annulla"
  },
  confirm_unpublish_dir: {
    title: "Annulla pubblicazione",
    text: function(dir){
      return `Annullare la pubblicazione della cartella <strong>"${dir}"</strong>?<br>Attenzione! Verrà annullata la pubblicazione di tutte le cartelle e i file contenuti`
    }
  },
  confirm_remove_file: {
    title: function(filename){
      return `Conferma eliminazione di "${filename}"`
    },
    text: "Si è sicuri di voler eliminare questo file?"
  },
  confirm_unpublish_file: {
    title: "Annulla pubblicazione",
    text: function(filename){
      return `Annullare la pubblicazione della risorsa <strong>"${filename}"</strong>?`
    }
  },
  uppy_error_file_format: {
    title: "Errore formato",
    message: "Formato del file non consentito"
  },
  cookie: {
    info1: "Questo sito utilizza i cookie, anche di terze parti: cliccando su",
    info2: "proseguendo nella navigazione, effettuando lo scroll della pagina o altro tipo di interazione col sito, acconsenti all'utilizzo dei cookie. Per maggiori informazioni o per negare il consenso a tutti o ad alcuni cookie, consulta l'informativa.",
    accept: "Accetta",
    read: "Leggi informativa"
  }
}
export const it = {...italian, ...uppy_italian}