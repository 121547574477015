$(document).on('click', '.folder-row', function(e) {
  if ( !$(e.target).closest('td').hasClass('action-details') ) {
    const row = $(this);
    const selectedFolderId = row.data('id');
    const defaultFolderId = $(".navbar-actions").data('folder-id') || 'none';
    const actualFolderId = $(".navbar-actions .details").data('folder-id');
    if ( String(selectedFolderId) === String(actualFolderId) ) {
      row.removeClass('selected');
      $.get(`/folders/${defaultFolderId}/selected`);
    } else {
      row.closest('.folder-wrapper').find('tr').removeClass('selected')
      row.addClass('selected')
      $.get(`/folders/${selectedFolderId}/selected`)
    }
  }
});

$(document).on('click', '.resource-row', function(e) {
  const row = $(this);
  if ( !$(e.target).closest('td').hasClass('action-details') ) {
    const defaultFolderId = $(".navbar-actions").data('folder-id') || 'none';
    const selectedResourceId = row.data('id');
    const selectedFolderId = row.data('folder-id');
    const actualResourceId = $(".navbar-actions .details").data('resource-id');
    if ( String(selectedResourceId) === String(actualResourceId) ) {
      row.removeClass('selected');
      $.get(`/folders/${defaultFolderId}/selected`);
    } else {
      row.closest('.folder-wrapper').find('tr').removeClass('selected');
      row.addClass('selected');
      $.get(`/folders/${selectedFolderId}/resources/${selectedResourceId}/selected`);
    }
  }
  else{
    MyApp.cookies.set('selected-resource-id', `#${row.attr("id")}`)
  }
});
