import {items} from "./menu-context-items";

export function initializeMenuContext() {
  $.contextMenu('destroy');

  $.contextMenu({
    selector: "tr.admin-dir.unpublished",
    items: {
      create_new: items.folder.create_new,
      edit: items.folder.edit,
      publish: items.folder.publish,
      upload: items.folder.upload,
      delete: items.folder.delete
    },
    events: {
      preShow : function(obj, e){
        if(!obj.hasClass('selected')){
         obj.click()
        }
        if(obj.hasClass('deletable')){
          e.data.items.delete.$node.removeClass('d-none')
        }else {
          e.data.items.delete.$node.addClass('d-none')
        }
      }
    }
  });

  $.contextMenu({
    selector: "tr.admin-dir.published",
    items: {
      create_new: items.folder.create_new,
      edit: items.folder.edit,
      unpublish: items.folder.unpublish,
      upload: items.folder.upload,
      delete: items.folder.delete
    },
    events: {
      preShow : function(obj, e){
        if(!obj.hasClass('selected')){
         obj.click()
        }
        if(obj.hasClass('deletable')){
          e.data.items.delete.$node.removeClass('d-none')
        }else {
          e.data.items.delete.$node.addClass('d-none')
        }
      }
    }
  });

  $.contextMenu({
    selector: ".context-menu-resource.published",
    items: {
      edit: items.resource.edit,
      unpublish: items.resource.unpublish,
      version: items.resource.version,
      download: items.resource.download,
      delete: items.resource.delete
    },
    events: {
      preShow : function(obj, e){
        if(!obj.hasClass('selected')){
          obj.click()
        }
        if(obj.hasClass('deletable')){
          e.data.items.delete.$node.removeClass('d-none')
        }else {
          e.data.items.delete.$node.addClass('d-none')
        }
      }
    }
  });

  $.contextMenu({
    selector: ".context-menu-resource.unpublished",
    items: {
      edit: items.resource.edit,
      publish: items.resource.publish,
      version: items.resource.version,
      download: items.resource.download,
      delete: items.resource.delete
    },
    events: {
      preShow : function(obj, e){
        if(!obj.hasClass('selected')){
          obj.click()
        }
        if(obj.hasClass('deletable')){
          e.data.items.delete.$node.removeClass('d-none')
        }else {
          e.data.items.delete.$node.addClass('d-none')
        }
      }
    }
  });
}
