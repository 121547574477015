$(document).on('change', '.js-user-role', function() {
  let role = $(this).val();
  switch (role) {
    case 'admin':
      $('.user-level-wrapper').addClass('d-none');
      $('.user-level-wrapper').find('select').val("");
      $('.user-regions-wrapper').addClass('d-none');
      $('.user-regions-wrapper').find('input').prop('checked', false);
      break;
    case 'manager':
      $('.user-level-wrapper').addClass('d-none');
      $('.user-level-wrapper').find('select').val("");
      $('.user-regions-wrapper').removeClass('d-none');
      break;
    default:
      $('.user-level-wrapper').removeClass('d-none');
      $('.user-regions-wrapper').removeClass('d-none');
  }
});

$(document).on('change', '.js-is-approved', function() {
  if($(this).is(':checked')) {
    $('.send-confirmation').removeAttr('disabled');
  } else {
    $('.send-confirmation').prop('checked', false);
    $('.send-confirmation').attr('disabled', 'disabled');
  }
});

//eliminazione avatar
$(document).on('fileclear', '.bootstrap-file-input', function() {
  var $this = $(this);
  var input_remove_id = $(this).data("input-remove");
  $(input_remove_id).val('1');
  setTimeout(function(){
    $this.fileinput('destroy');
    $this.fileinput({theme: "fas",
      showUpload: false,
      browseLabel: '',
      removeLabel: ''});
  }, 100);
}).on('fileselect', '.bootstrap-file-input', function() {
  var input_remove_id = $(this).data("input-remove");
  $(input_remove_id).val('');
});

$(document).on('click', '.navbar-side', function(e) {
  console.log("CLICKED!");
});
