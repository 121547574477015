import "bootstrap";
import "bootstrap-material-design";
import iziToast from "izitoast";
import OverlayScrollbars from "overlayscrollbars/js/OverlayScrollbars";
import MetisMenu from "metismenujs";
import Rails from "@rails/ujs";
import Cookies from 'js-cookie/src/js.cookie'

import "jquery-contextmenu";

import "select2"
import 'metismenujs/dist/metismenujs'
import "bootstrap-fileinput";
import "bootstrap-fileinput/themes/fas/theme";
import "bootstrap-fileinput/js/locales/it";

import './jquery.typeahead';
import './data-confirm-modal/data-confirm-modal';
import "./jstree"
import "./jquery-tagsinput/jquery.tagsinput-revisited"

import './users';
import './folders';
import './search';
import './cookie_law';

import {initializeApp} from "./app";
import {initializeMenuContext} from "./menu_context/menu-context-init";
import {initializeResourceUppy} from "./uppy/uppy-init";


window.MyApp = {
  initializeApp: initializeApp,
  initializeResourceUppy: initializeResourceUppy,
  cookies: Cookies
};

iziToast.settings({
  timeout: 8000,
  position: "topRight"
});

$(document).ajaxStart(e => { $('.ajax-load').removeClass('d-none') });
$(document).ajaxSuccess(e => { Rails.fire(e.target, 'ajax:success'); });
document.addEventListener("ajax:success", function() {
  initializeApp();
  $('.ajax-load').addClass('d-none')
  // se serve inizializzare anche qui il MenuContext trovare un modo per inibirlo quando clicco col tasto dx sul folder, perchè mi fa
  // sparire il menu contestuale visto che viene fatto il trigger click sulla riga per selezionarlo
  // initializeMenuContext();
});


/**
 * Con i turbolink abilitati questa sostituisce la document.onload, ovvero $(function() { ... })
 */
document.addEventListener("turbolinks:load", function() {
  initializeApp();
  initializeMenuContext();

  let $archiveTree = $('#sidebarArchiveTree');
  $archiveTree.jstree(
    {
      "core": {
        'data' : {
          'url' : '/folders.json',
        },
        "themes":{
          "icons": false,
          "ellipsis": true
        }
      },
      "plugins" : ["wholerow"]
    }
  );
  $archiveTree.on("select_node.jstree", function (e, data) {
    window.location.href = data.node.a_attr.href;
  });
  if($('#bagg-menu').length > 0){
    MetisMenu.attach('#bagg-menu');
  }

  $('body').bootstrapMaterialDesign();

  OverlayScrollbars(document.querySelectorAll('#sidebar > .sidebar-content'), {
    scrollbars: {
      autoHide: "leave"
    }
  });
});
