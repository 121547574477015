const uppy_spanish = require('@uppy/locales/lib/es_ES');
const spanish = {
  info: "Info",
  create_new_dir: "New Directory",
  edit: "Edit",
  delete: "Delete",
  upload: "Upload File",
  download: "Download",
  confirm_remove_dir: {
    title: function(dir){
      return `Confirm delete "${dir}"`
    },
    text: "Deleting the folder implies deleting all the files and folders contained in it. Confirm deletion?",
    commit: "Confirm",
    cancel: "Cancel"
  },
  confirm_unpublish_dir: {
    title: "Annulla pubblicazione",
    text: function(dir){
      return `Annullare la pubblicazione della cartella <strong>"${dir}"</strong>?<br>Attenzione! Verrà annullata la pubblicazione di tutte le cartelle e i file contenuti`
    }
  },
  confirm_remove_file: {
    title: function(filename){
      return `Conferma eliminazione di "${filename}"`
    },
    text: "Si è sicuri di voler eliminare questo file?"
  },
  confirm_unpublish_file: {
    title: "Annulla pubblicazione",
    text: function(filename){
      return `Annullare la pubblicazione della risorsa <strong>"${filename}"</strong>?`
    }
  },
  uppy_error_file_format: {
    title: "Errore formato",
    message: "Formato del file non consentito"
  },
  cookie: {
    info1: "Questo sito utilizza i cookie, anche di terze parti: cliccando su",
    info2: "proseguendo nella navigazione, effettuando lo scroll della pagina o altro tipo di interazione col sito, acconsenti all'utilizzo dei cookie. Per maggiori informazioni o per negare il consenso a tutti o ad alcuni cookie, consulta l'informativa.",
    accept: "Accetta",
    read: "Leggi informativa"
  }
}
export const es = {...spanish, ...uppy_spanish}
